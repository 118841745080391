// 申遗承诺
import SHZT from './sn-table-column-shzt';

const selfData = {
    loading: false,
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            columnsData: [
                // {
                //     label: "对象分类",
                //     prop: "YCYSFL_1",
                //     minWidth: ""
                // },
                // {
                //     label: "异常发生位置",
                //     prop: "YCFSWZ",
                //     minWidth: ""
                // },

                {
                    label: "遗产组成",
                    prop: "YCYSFL_2",
                    minWidth: "120"
                },
                {
                    label: "上报类型",
                    prop: "YCLXNAME",
                    width: "120"
                },
                {
                    label: "评估",
                    prop: "PG_DESC",
                    width: "80"
                },
                {
                    label: "事件描述",
                    prop: "SJMS",
                },
                {
                    label: "上报人",
                    prop: "CJRMC",
                    width: "120"
                },
                {
                    prop: 'LRFS',
                    label: '上报来源',
                    transList: {
                        2: "移动采集系统",
                        4: "小程序"
                    }
                },
                {
                    label: "上报人电话",
                    prop: "SBRDH",
                    width: "120"
                },
                {
                    label: "上报时间",
                    prop: "SBSJ",
                    width: "200"
                },
                {
                    label: "是否转为异常",
                    prop: "SFZYC",
                    minWidth: "",
                    transList: {
                        null: '否',
                        '': '否',
                        0: '否',
                        1: '是'
                    },
                },
                {
                    label: "附件",
                    prop: "LJ",
                    showFileBtn: true,
                    width: "50"
                },
                SHZT
            ],
            // 操作列
            columnsBtn: {
                buttonData: []
            },
            width: 150
        },
    },

    snSearch: {
        inputData: [{
            label: '关键字',
            placeholder: "请输入关键字",
            value: 'keyword',
            operateType: 'search',
            isShow: true,
            default: ""
        }],
        treeselectData: [{
            label: "异常类型",
            clearable: true,
            placeholder: "异常类型",
            class: "selectRound",
            width: "160px",
            list: [],
            optionLabel: "label",
            optionValue: "id",
            value: "YCLX",
            operateType: "search",
            isShow: true,
            default: ""
        }]
    },
    snButton: {
        buttonData: [
            {
                btnType: 'button',
                operateType: 'buttonExport',
                showMethod: 'showExportBtn',
                name: '导出',
                round: true,
                backColor: '#ffcc66',
                color: '#fff'
            }
        ]
    }
};
export default selfData;